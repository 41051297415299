@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap");

$theme-colors: (
    "site-primary": #0fcdba,
    "site-secondary": #ed9c00,
    "site-black": #000000,
);

@import "node_modules/bootstrap/scss/bootstrap";

body {
    overflow-x: hidden;
    font-family: "Roboto", sans-serif !important;
}

html {
    font-size: 18px;
    @media (max-width: 1799.98px) {
        font-size: 17px;
    }
    @media (max-width: 1399.98px) {
        font-size: 16px;
    }
    @media (max-width: 1199.98px) {
        font-size: 13px;
    }
    @media (max-width: 991.98px) {
        font-size: 12px;
    }
    @media (max-width: 567.98px) {
        font-size: 11px;
    }
}
$weights: 100 200 300 400 500 500 600 700 800;
@each $weight in $weights {
    .fw--#{$weight} {
        font-weight: $weight;
    }
}
@for $size from 9 through 96 {
    .fs--#{$size} {
        font-size: $size/16 + rem;
    }
}

.cursor-pointer {
    cursor: pointer;
}
.hero-section {
    &__arrow-right {
        position: absolute;
        right: 30%;
        // top: 55%;
        bottom: -120%;
    }

    /// shown on small screen
    &__PromotionBadge-right {
        position: absolute;
        right: 8%;
        bottom: -10%;
        // bottom: -140%;
        height: 100px !important;
        width: 100px !important;
    }
    &__promotion-badge {
        position: absolute;
        left: -15%;
        bottom: 8%;
        height: 35%;
    }
    &__hero-image {
        height: 90% !important;
    }

    @media screen and (min-width: 1115.99px) {
        h1 {
            font-size: 30px !important;
        }
    }

    @media screen and (max-width: 991.99px) {
        &__hero-image {
            height: 100% !important;
        }
        &__promotion-badge {
            position: absolute;
            left: -15%;
            bottom: -5%;
            height: 35%;
        }
        h1 {
            font-size: 18px !important;
        }
    }
    @media screen and (max-width: 786.99px) {
        &__hero-image {
            height: 100% !important;
        }
        &__promotion-badge {
            position: absolute;
            left: -3%;
            bottom: -5%;
            height: 25%;
        }
        h1 {
            font-size: 28px !important;
        }
    }

    @media screen and (max-width: 770.99px) {
        h1 {
            font-size: 26px !important;
        }
        p {
            font-size: 17px !important;
        }
    }
    @media screen and (max-width: 499.99px) {
        h1 {
            font-size: 20px !important;
        }
        p {
            font-size: 13px !important;
        }
        &__PromotionBadge-right {
            position: absolute;
            right: 0%;
            bottom: 2%;
            // bottom: -140%;
            height: 80px !important;
            width: 80px !important;
        }
    }
    @media screen and (max-width: 340.99px) {
        &__PromotionBadge-right {
            position: absolute;

            height: 60px !important;
            width: 60px !important;
        }
    }
}
.heading-modal {
    position: absolute;
    margin-top: -23%;

    align-self: center;
}

/* Hide the default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-container {
    display: block;
    position: relative;
    cursor: pointer;

    font-size: 1.6rem;
    user-select: none;
}

/* Create a custom checkbox */
.checkmark {
    --clr: #0fcdba;
    position: relative;
    top: 0;
    left: 0;
    height: 1em;
    width: 1em;
    border-radius: 0.2rem;
    background-color: transparent;
    transition: 300ms;
    border: 1.5px solid #0fcdba;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: var(--clr);
    border-radius: 0.2rem;
    animation: pulse 500ms ease-in-out;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 0.3em;
    top: 0.15em;
    width: 0.25em;
    height: 0.5em;
    border: solid #ffffff;
    border-width: 0 0.15em 0.15em 0;
    transform: rotate(45deg);
}

.modal-content {
    background-color: rgba(255, 255, 255, 0) !important;
    border: none !important; /* Adjust the last value (0.7) for the desired opacity */
    /* You can also set other styles like padding, border-radius, etc. */
}
// .form-height {
//     max-height: 30rem !important;
// }
.solution-image {
    bottom: 0%;
    left: 0%;
}
.border-bottom-setting {
    border-bottom: 2px solid #0fcdba;
}
.make-responsive {
    @media screen and (max-width: 360.99px) {
        font-size: 9px !important;
    }
}
.modal-scrollable {
    max-height: calc(100vh - 200px); /* Adjust the value as needed */
    overflow-y: auto;
}

.scrollable-content {
    padding: 15px; /* Adjust padding as needed */
}
.dropdown-setting {
    height: 42px !important;
}

// for radio-button
.radio-button {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 10px;
    position: relative;
    align-items: center;
    color: white;
}

.radio-button input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.radio {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ccc;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    transform: translateZ(-25px);
    transition: all 0.3s ease-in-out;
}

.radio::before {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    top: 3px;
    left: 3px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.radio-button input[type="radio"]:checked + .radio {
    border-color: #0fcdba;
    transform: translateZ(0px);
    background-color: #0fcdba;
}

.radio-button input[type="radio"]:checked + .radio::before {
    opacity: 1;
}

.text-responsive {
    text-align: center;
    @media screen and ((max-width: 350.99px)) {
        p {
            font-size: x-small !important;
        }
    }
}

.modal-open .modal {
    backdrop-filter: brightness(0.5) !important;
}
.from2-arrow {
    position: absolute;
    right: -6px;
    bottom: -25px;
    @media screen and ((max-width: 799.99px)) {
        height: 30%;
    }
    @media screen and ((max-width: 380.99px)) {
        height: 20%;
    }
}
.form2-text-responsive {
    @media screen and ((min-width: 799.99px)) {
        h4 {
            font-size: 19px !important;
        }
        p {
            font-size: 14px !important;
        }
    }
}
